import React, { Component } from 'react';
import ContactMethod from "./ContactMethod";

class ContactList extends Component {
    renderContactMethods() {
        let methods = this.props.data,
            contactTable = [];

        for (let method in methods) {
            let currentMethod = methods[method];

            contactTable.push(
                <ContactMethod
                    key={`method-${method}`}
                    name={currentMethod.name}
                    displayName={currentMethod.displayName}
                    icon={currentMethod.icon}
                    url={currentMethod.url} />
            );
        }

        return contactTable;
    }

    render() {
        return (
            <div className={"contact-container"}>
                {this.renderContactMethods()}
            </div>
        );
    }
}

export default ContactList;
