import React, { Component } from 'react';
import Page from "../Page";

class NoRoute extends Component {
    render() {
        return (
            <Page pageTitle={"404 - Page Not Found"} pageId={"404"}>
                <h4>The page you are looking for was not found.</h4>
                <p>How did you even get here...?</p>
            </Page>
        );
    }
}

export default NoRoute;
