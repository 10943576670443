import React, { Component } from 'react';

class Skill extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    renderDetails () {
        if (this.props.details !== ""
            && typeof this.props.details !== "undefined"
            && this.props.details !== null) {

            return (
                <div className={"skill-details"}>
                    <p>
                        {this.props.details}
                    </p>
                </div>
            );
        }
        return null;
    }

    toggleState(e) {
        this.setState({open: !this.state.open});
    }

    render() {
        return (
            <div className={`skill ${this.state.open ? 'open' : 'closed'} ${this.renderDetails() == null ? 'no-details' :''}`}
                 onClick={ e => this.toggleState(e)}>
                <div className={"skill-header"}>
                    <span className={"skill-name"}>
                        <i className={`skill-icon icon icon-${this.props.icon}`} /> {this.props.name}
                    </span>
                    <span className={"skill-rating-container"}>
                        <span className={"skill-rating"} style={{width: `${this.props.rating}%`}}>
                            <span className={"skill-value"}>{this.props.rating}%</span>
                        </span>
                    </span>
                </div>
                { this.renderDetails() }
            </div>
        );
    }
}

export default Skill;
