import React, {Component} from 'react';
import Page from "../Page";
import Section from "../Section";
import contact from "../../assets/data/contact";
import portfolio from "../../assets/data/portfolio";
import PortfolioItem from "../PortfolioItem";
import {NavLink} from "react-router-dom";

class Portfolio extends Component {
    renderItems() {
        let arr = [];

        for (let item in portfolio) {
            arr.push(<PortfolioItem key={`portfolio-item-${item}`}{...portfolio[item]} />)
        }

        return arr;
    }

    render() {
        return (
            <Page pageTitle={"Portfolio"} pageId={"portfolio"}>
                <Section id={"section1"} title={"Previous Work"}>
                    <div className={"portfolio-container"}>
                        <p>
                            <span>This page is currently under maintenance.</span><br />
                            <span>Please <NavLink className={"inline-link"} to={"contact"}>get in touch</NavLink> or check out my <a className={"inline-link"} href={contact.github.url}>Github</a> for now.</span>
                        </p>
                    </div>
                </Section>
            </Page>
        );
    }
}

export default Portfolio;
