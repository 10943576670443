import React, { Component } from 'react';

class SkillContainer extends Component {
    render() {
        return (
            <div className={"skills-container"}>
                <h3 className={"subtitle"}>{this.props.title}</h3>
                {this.props.children}
            </div>
        );
    }
}

export default SkillContainer;
