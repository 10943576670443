const skills = {
    development: {
        name: "Development",
        skills: {
            magento: {
                name: "Magento 2",
                icon: "magento",
                rating: "65",
                details: "Good experience with both backend and frontend work. This includes module development, layout, PHP, plugins, knockout, mixins and SASS/LESS."
            },
            git: {
                name: "Git",
                icon: "git",
                rating: "70",
                details: "A good working knowledge of Git. Generally I use the CLI however switch to a GUI for complex tasks."
            },
            javascript: {
                name: "JavaScript",
                icon: "javascript",
                rating: "85",
                details: "Excellent working knowledge including ES6 features. Mostly client-side focused. I consider JavaScript to be my primary language."
            },
            react: {
                name: "React",
                icon: "react",
                rating: "35",
                details: "Recently invested some time into learning React. Still a beginner, but enjoying it a lot. Also, this website is made with React!"
            },
            jquery: {
                name: "jQuery",
                icon: "jquery",
                rating: "80",
                details: "Used very frequently in personal projects and across multiple Magento sites. Includes jQuery UI."
            },
            sass: {
                name: "SASS",
                icon: "sass",
                rating: "85",
                details: "Also LESS. Personally I prefer SASS because of familiarity and experience."
            },
            php: {
                name: "PHP",
                icon: "php",
                rating: "70",
                details: "No explicit experience with frameworks aside from Magento. OOP concepts, patterns and principles were learnt through 2-3 years of university and employment."
            },
            html: {
                name: "HTML",
                icon: "html5",
                rating: "95",
                details: "Excellent understanding of different elements and features."
            },
            css: {
                name: "CSS",
                icon: "css3",
                rating: "95",
                details: "Excellent knowledge of selectors and properties."
            },
            android: {
                name: "Android",
                icon: "android",
                rating: "20",
                details: "Basic knowledge of Android Development from University course."
            }
        }
    },
    software: {
        name: "Software",
        skills: {
            ubuntu: {
                name: "Ubuntu",
                icon: "ubuntu",
                rating: "70",
                details: "Previously used as primary OS for my laptop. I also have some experience with Ubuntu servers."
            },
            archlinux: {
                name: "Arch Linux",
                icon: "archlinux",
                rating: "60",
                details: "I currently use this OS for all development on both my laptop and desktop."
            },
            mac: {
                name: "Mac OS",
                icon: "mac",
                rating: "70",
                details: "I do not use this personally, but has been used during employment."
            },
            windows: {
                name: "Windows",
                icon: "windows",
                rating: "90",
                details: "This is the primary OS used on my desktop, mainly for software compatibility."
            }
        }
    }
};

export default skills;