import React, {Component} from 'react';
import Page from "../Page";
import Section from "../Section";
import ContactList from "../ContactList";
import contactMethods from "../../assets/data/contact"

class Contact extends Component {
    render() {
        return (
            <Page pageTitle={"Contact"} pageId={"contact"}>
                <Section id={"section1"} title={"Get in touch"}>
                    <ContactList data={contactMethods}/>
                </Section>
            </Page>
        );
    }
}

export default Contact;
