import React, { Component } from 'react';

class PortfolioItem extends Component {

    render() {
        return (
            <div className={`portfolio-item`}>
                <div className={"portfolio-image"}>
                    <a href={this.props.url}><img src={this.props.image} alt={"portfolio item"}/></a>
                </div>
                <div className={"portfolio-details"}>
                    <a href={this.props.url}><h4 className={`portfolio-name`}>{this.props.name}</h4></a>
                    <div className={"icons"}>
                        {this.props.icons}
                    </div>
                    <span className={"portfolio-description"}>
                        {this.props.description}
                    </span>
                </div>
            </div>
        );
    }
}

export default PortfolioItem;
