import React, { Component } from 'react';

class ContactMethod extends Component {
    render() {
        return (
            <div className={"contact-method"}>
                <a className={"method-icon-link"} href={this.props.url}>
                    <i className={`method-icon icon icon-${this.props.icon}`} />
                </a>
                <div className={"method-wrapper"}>
                    <span className={"method-name"}>
                        {this.props.name}
                    </span>
                    <a className={"method-url"} href={this.props.url}>
                        <span className={"method-display-name"}>{this.props.displayName}</span>
                    </a>
                </div>
            </div>
        );
    }
}

export default ContactMethod;
