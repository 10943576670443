import React, { Component } from 'react';

class Section extends Component {
    render() {
        return (
            <section id={this.props.id} className={"page-section"}>
                <h2 className={"section-title"}>{this.props.title}</h2>
                {this.props.children}
            </section>
        );
    }
}

export default Section;
