import React, {Component} from 'react';
import Page from "../Page";
import Skills from "../Skills";
import skillData from "../../assets/data/skills"
import Section from "../Section";
import {NavLink} from "react-router-dom";

class Home extends Component {
    getAge() {
        let today = new Date(),
            birthDate = new Date("7/30/1995"),
            age = today.getFullYear() - birthDate.getFullYear(),
            m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age = age - 1;
        }

        return age;
    }

    render() {
        return (
            <Page pageTitle={"Home"} pageId={"home"}>
                <Section id={"section1"} title={"About"}>
                    <div className={"about"}>
                        <p>
                            <span>Hi, I'm Rob. I'm a {this.getAge()} year old full-stack web developer from Stourbridge, England. </span>
                            <span>I am predominantly a JavaScript and Magento 2 developer, but a detailed breakdown of skills can be seen below.</span>
                        </p>
                        {/*<br />*/}
                        {/*<p>After finishing I'll be returning to <a className={"inline-link"} href={"https://fisheye-webdesign.co.uk/" } target={"_blank"}>Fisheye</a> where I did my placement year.</p>*/}
                    </div>
                </Section>
                <Section id={"section2"} title={"Skills"}>
                    <Skills data={skillData} />
                    <h3 className={"subtitle"}>Additional Info</h3>
                    <div className={"other-skills"}>
                        <p>
                            <span>I have dabbled with other languages such as Java, C# and C++ in the past. I am always willing to learn new things and expand my current skillset. </span>
                        </p>
                        <br />
                        <p>
                            <span>I like to think I am adaptable. I'll attempt any task given to me, and won't refuse unfamiliar tools. </span>
                            <span>That being said, my preferred IDE's are from the Jetbrains family. </span>
                        </p>
                        <br />
                        <p>
                            <span>Want to know more, or just have a chat? <NavLink className={"inline-contact-link"} to={"/contact"}>Get in touch</NavLink></span>
                        </p>
                    </div>
                </Section>
            </Page>
        );
    }
}

export default Home;
