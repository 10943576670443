import React, {Component} from 'react';
import { BrowserRouter } from "react-router-dom";
import { Route } from "react-router";
import {AnimatedSwitch} from "react-router-transition";
import {bounceTransition, mapStyles} from "./components/utils/transitions";
import './App.scss';
import Home from "./components/route/Home";
import NoRoute from "./components/route/NoRoute";
import Contact from "./components/route/Contact";
import Portfolio from "./components/route/Portfolio";
import Header from "./components/Header";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <div>
                    <Header snackbar={this.snackbar}/>

                    <AnimatedSwitch
                        atEnter={bounceTransition.atEnter}
                        atLeave={bounceTransition.atLeave}
                        atActive={bounceTransition.atActive}
                        mapStyles={mapStyles}
                        className={"switch-wrapper"}>

                        <Route exact={true} path={"/"} component={Home}/>
                        <Route exact={true} path={"/portfolio"} component={Portfolio}/>
                        <Route exact={true} path={"/contact"} component={Contact}/>
                        <Route component={NoRoute}/>
                    </AnimatedSwitch>

                    {/*<Footer />*/}
                </div>
            </BrowserRouter>
        );
    }
}

export default App;