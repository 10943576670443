import React, {Component} from 'react';
import Skill from "./Skill";
import SkillContainer from "./SkillContainer";

class Skills extends Component {
    renderSkills() {
        let skillContainers = [], skills = this.props.data;

        for (let container in skills) {
            let skillTable = [],
                currentContainer = skills[container];

            for (let skill in currentContainer.skills) {
                let currentSkill = skills[container].skills[skill];

                skillTable.push(
                    <Skill
                        key={`skill-${skill}`}
                        {...currentSkill} />
                );
            }

            skillContainers.push(
                <SkillContainer
                    key={container}
                    title={skills[container].name}
                    children={skillTable} />
            );
        }

        return skillContainers;
    }

    render() {
        return (
            <div className={"skills-breakdown"}>
                {this.renderSkills()}
            </div>
        );
    }
}

export default Skills;

