const contact = {
    email: {
        name: "Email",
        displayName: "rob@aimes.dev",
        url: "mailto:rob@aimes.dev",
        icon: "envelope"
    },
    github: {
        name: "Github",
        displayName: "robaimes",
        url: "https://github.com/robaimes",
        icon: "github"
    },
    twitter: {
        name: "Twitter",
        displayName: "robaimes",
        url: "https://twitter.com/robaimes",
        icon: "twitter"
    },
    linkedin: {
        name: "LinkedIn",
        displayName: "robaimes",
        url: "https://www.linkedin.com/in/robaimes",
        icon: "linkedin"
    }
};

export default contact;