const portfolio = {
    module_gallery: {
        name: "Module_ProductGallery",
        description: "A proof of concept module to replace the slow Fotorama gallery implementation in Magento 2 core.",
        url: "",
        image: ""
    },
    module_random: {
        name: "Module_RandomProduct",
        description: "Display a clickable link to send the user to a random product on the site.",
        url: "",
        image: ""
    },
    website: {
        name: "aimes.dev",
        description: "You're here! This website is made using React.js. I needed to replace my old website and also wanted to learn React, so this was born.",
        url: "",
        image: ""
    },
};

export default portfolio;