import React, { Component } from 'react';
import {NavLink, withRouter} from "react-router-dom";

class Header extends Component {

    getPageTitle() {
        let path = this.props.location.pathname.slice(1);
        path = path.charAt(0).toUpperCase() + path.slice(1);

        return path === "" ? "Home" : path;
    }

    render() {
        return (
            <header className={"header"}>
                <div className={"header-wrapper"}>
                    <div className={"page-title-container"}>
                        <h1 className={"page-title"}>Rob Aimes</h1>
                        <h3 className={"page-subtitle"}>{this.getPageTitle()}</h3>
                    </div>
                    <nav className={"nav-main"}>
                        <NavLink exact className={"nav-link"} to={"/"}>Home</NavLink>
                        <NavLink className={"nav-link"} to={"/portfolio"}>Portfolio</NavLink>
                        <NavLink className={"nav-link"} to={"/contact"}>Contact</NavLink>
                    </nav>
                </div>
            </header>
        );
    }
}

export default withRouter(Header);
