import React, { Component } from 'react';

class Page extends Component {
    render() {
        return (
            <div className={`page-wrapper page-${this.props.pageId}`}>
                <main className={this.props.pageId}>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

export default Page;
